<template>
  <div class="calc__data" v-if="!loading">
    <component :is="'Step' + step"></component>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
  import Step1 from './components/Step1.vue';
  import Step2 from './components/Step2.vue';
  import Step3 from './components/Step3.vue';
  import Step4 from './components/Step4.vue';
  import Step5 from './components/Step5.vue';
  import Step6 from './components/Step6.vue';

  export default {
    name: "Calc",
    components: {
      Step1,
      Step2,
      Step3,
      Step4,
      Step5,
      Step6
    },
    computed: {
      ...mapState('calc', [
        'loading',
        'items',
        'steps',
        'step',
        'values',
        'showPrices'
      ])
    },
    methods: {
      ...mapActions({
        fetchContext: 'calc/fetchContext',
        pushText: 'calc/pushText'
      }),
      ...mapMutations({
        showPrice: 'calc/showPrice'
      }),
      showFormMaybe() {
        if (this.step === 6 && !this.showPrices) {
          document.dispatchEvent(new CustomEvent('show-calc-form'));
        }
      }
    },
    created: function () {
      this.fetchContext();
      document.addEventListener('show-price', () => {
        this.showPrice();
      });
    },
    mounted() {
      this.showFormMaybe();
    },
    watch: {
      values: {
        deep: true,
        handler(selectedValue, old) {
          this.pushText()
        }
      },
      step() {
        this.showFormMaybe();
      }
    }
  }
</script>