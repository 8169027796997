const showForm = () => {
  document.querySelector('[data-calc-request-form]').classList.add('_show');
};

const hideForm = () => {
  document.querySelector('[data-calc-request-form]').classList.remove('_show');
};

document.addEventListener('show-calc-form', () => {
  showForm();
});

document.addEventListener('show-price-full', () => {
  document.dispatchEvent(new CustomEvent('show-price'));
  hideForm();
});