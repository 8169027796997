<template>
    <div class="step step_4">
        <div class="step__name">
            <div class="step__name-num">
                4
            </div>
            <div class="step__name-text">
                Внешняя отделка дома
            </div>
        </div>

        <div class="step__inputs">
            <StepInput code="FACING" title="Укажите облицовочный материал стен" />

            <StepInput code="MORE" title="Выберите дополнительные элементы" />
        </div>

        <div class="step__buttons">
            <a href="javascript:void(0);" class="step__prev button button_transparent" @click="prevStep">
                &larr; Назад
            </a>

            <a href="javascript:void(0);" class="step__next button button_default" @click="nextStep" v-if="isNextAllowed">
                Далее &rarr;
            </a>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
    import StepInput from './StepInput.vue';
    import scroll from "../services/scroll.service.js";

    export default {
        name: "Step4",
        components: {
            StepInput
        },
        computed: {
            ...mapGetters('calc', [
                'isNextStepAllowed',
            ]),
            isNextAllowed() {
                return this.isNextStepAllowed(['FACING']);
            }
        },
        methods: {
            ...mapMutations('calc', [
                'nextStep',
                'prevStep'
            ])
        },
        mounted() {
          scroll();
        }
    }
</script>

<style>

</style>