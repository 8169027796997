import Vue from 'vue';
import App from './App.vue';

import store from './store/index.js';

Vue.filter('price', (value) => {
  if (!value) return '';
  value = parseInt(value, 0);
  return value.toString().split(/(?=(?:...)*$)/).join(' ');
});

if (document.querySelector('#app')) {
  const vm = new Vue({
    store,
    el: '#app',
    render: h => h(App),
  });
}