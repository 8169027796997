import Jax from '../components/jax/jax';

function replaceContent(newResponse) {
  const pageElement = document.createElement('div');
  pageElement.innerHTML = newResponse;

  const newProjects = pageElement.querySelector('[data-category-projects]');
  const oldProjects = document.querySelector('[data-category-projects]');
  oldProjects.innerHTML = newProjects.innerHTML;
}

function initFilter() {
  document.querySelectorAll('[data-category-filter] input').forEach((input) => {
    console.log(input);
    input.addEventListener('change', () => {
      let jax = new Jax();
      let form = document.querySelector('[data-category-filter]');

      jax.send(new FormData(form)).then((response) => {
        replaceContent(response);
      })
    })
  })
}

if (document.querySelector('[data-category-filter]')) {
  initFilter();
}