<template>
    <div class="step step_6">
        <div class="step__name step__name_center" v-if="showPrices">
            <div class="step__name-text">
                Предварительная смета на строительство дома компанией&nbsp;СТРиТ
            </div>
        </div>

        <div class="estimate" v-if="showPrices">
            <div class="estimate__steps">
                <div class="estimate__step" v-for="(step, key) in steps" :key="key">
                    <div class="estimate__step-name">
                        {{ step.name }}
                    </div>
                    <div class="estimate__step-items">
                        <ul class="estimate__items">
                            <li class="estimate__item" v-for="(item, key) in getEstimateItemsByStep(step.id)" :key="key">
                                <div class="estimate__item-label">
                                    {{item.name}}
                                </div>
                                <div class="estimate__item-value">
                                    {{item.value}}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="estimate__total" v-if="step.formula.trim().length > 0">
                        <div class="estimate__total-label">
                            Итоговая стоимость этапа:
                        </div>
                        <div class="estimate__total-value">
                            {{calcFormula(step.formula)|price}} руб.
                        </div>
                    </div>
                </div>
            </div>
            <div class="estimate__result">
                <div class="estimate__result-label">
                    Общая стоимость всего строительства:
                </div>
                <div class="estimate__result-value">
                    {{calcTotal|price}} руб.
                </div>
            </div>
            <div class="estimate__back">
                <a href="javascript:void(0);" class="estimate__back-link" @click="prevStep">
                    &larr; Изменить параметры дома
                </a>
            </div>
        </div>
        <div class="estimate__push" v-else>

        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
    import StepInput from './StepInput.vue';
    import scroll from "../services/scroll.service.js";

    export default {
        name: "Step6",
        components: {
            StepInput
        },
        computed: {
            ...mapState('calc', [
              'steps',
              'showPrices'
            ]),
            ...mapGetters('calc', [
                'getEstimateItemsByStep',
                'calcFormula',
                'calcTotal'
            ])
        },
        methods: {
            ...mapMutations('calc', [
                'nextStep',
                'prevStep'
            ])
        },
        mounted() {
          scroll();
        },
      destroyed() {
      }
    }
</script>

<style>

</style>