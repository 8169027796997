const DefaultService = {
  calcFormula(formula, items, values) {
    const baseFormula = formula;
    const variables = formula.match(/{.*?}/g);
    if (variables) {
      variables.forEach((variable) => {
        const variableValue = this.getVariableValue(variable, items, values)
        console.log(variable + ' = ' + variableValue);
        formula = formula.replace(variable, this.getVariableValue(variable, items, values))
      });
    }
    const result = parseFloat(eval(formula));
    if (variables) {
      console.log(baseFormula + ' = ' + result);
    }
    return result;
  },
  getVariableValue(variableName, items, values) {
    // Normalize code
    if (variableName.startsWith('{')) {
      variableName = variableName.substr(1, variableName.length - 2)
    }
    // Not set variable
    if (!values[variableName]) {
      // Variable for calculations only
      item = items.find((item) => item.group_code === variableName && (parseInt(item.hidden, 10)));
      if (item) {
        return this.calcFormula(item.formula, items, values);
      }
      // No value
      return 0;
    }
    const value = values[variableName];
    // Fixed variables
    if ([
      'WIDTH',
      'LENGTH'
    ].indexOf(variableName) >= 0) {
      return parseFloat(value);
    }
    let item = null;
    if (value === true) {
      item = items.find((item) => item.group_code === variableName);
    } else {
      item = items.find((item) => item.id === value);
    }
    if (item) {
      return this.calcFormula(item.formula, items, values);
    }
    return 0;
  },
  parseFloat(value) {
    const float = parseFloat(value);
    return float || 0;
  }
};

export default DefaultService;