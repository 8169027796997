import FetchService from '../../services/fetch.service.js';
import DefaultService from '../../services/default.service.js';

import Vue from 'vue';

let value = localStorage.getItem('CALC_VALUES');
if (value) {
  value = JSON.parse(value);
}

let step = localStorage.getItem('CALC_STEP');
let showPrice = !!localStorage.getItem('CALC_SHOW_PRICE');

const calcModule = {
  namespaced: true,
  state: {
    loading: true,

    step: step ? parseInt(step, 10) : 1,
    showPrices: showPrice,

    items: [],
    steps: [],

    values: value ? value : {
      WIDTH: 10,
      LENGTH: 10
    }
  },
  getters: {
    asText(state, getters) {
      const text = [];
      let total = 0;
      state.steps.forEach((step) => {
        text.push(step.name);
        text.push("\n");

        getters.getEstimateItemsByStep(step.id).forEach((item) => {
          text.push(item.name + ': ' + item.value);
        });
        text.push("\n");

        if (step.formula.trim().length) {
          const totalStep = getters.calcFormula(step.formula);
          text.push(`Итоговая стоимость этапа: ${totalStep} руб`);
          total += totalStep;
        }
        text.push("\n");
      });
      text.push("\n");
      text.push(`Общая стоимость строительства: ${total}`)
      return text.join("\n");
    },
    isNextStepAllowed(state) {
      return (elements) => {
        return elements.every((element) => !!state.values[element])
      }
    },
    getRadios(state) {
      return (name) => {
        return state.items.filter(element => element.group_code === name)
      }
    },
    getCheckboxes(state) {
      return (name) => {
        return state.items.filter(element => element.group_code.startsWith(name + '#'))
      }
    },
    getValue(state) {
      return (name) => {
        return state.values[name];
      }
    },
    isChecked(state) {
      return (name) => {
        return state.values[name];
      }
    },
    isCheckedRadio(state) {
      return (name, value) => {
        return state.values[name] === value;
      }
    },
    getEstimateItemValue(state) {
      return (item) => {
        let value = 'нет';
        let currentValue = state.values[item.group_code];
        if (currentValue === true) {
          value = 'да';
        } else if (currentValue === false || currentValue === undefined) {
          value = 'нет';
        } else {
          if (currentValue === item.id) {
            value = item.name;
          } else {
            value = null;
          }
        }
        return value;
      }
    },
    getEstimateItemsByStep(state, getters) {
      return (stepId) => {
        const result = [];
        const filtered = state.items.filter(item => item.step_id === stepId && !parseInt(item.hidden_total, 10));
        if (stepId == 1) {
          result.push({
            name: 'Ширина',
            value: state.values['WIDTH'] + ' м.'
          });
          result.push({
            name: 'Длина',
            value: state.values['LENGTH'] + ' м.'
          });
        }
        filtered.forEach((item) => {
          const value = getters.getEstimateItemValue(item);
          if (value) {
            result.push({
              name: item.menu,
              value: value
            })
          }
        })
        if (stepId == 1) {
          result.push({
            name: 'Общая площадь дома',
            value: parseInt(getters.calcFormula('{AREA}'), 10) + ' м²'
          });
        }
        return result;
      }
    },
    calcFormula(state) {
      return (formula) => {
        return DefaultService.calcFormula(formula, state.items, state.values);
      }
    },
    calcTotal(state) {
      let total = 0;
      state.steps.forEach((step) => {
        if (step.formula.trim().length) {
          total += DefaultService.calcFormula(step.formula, state.items, state.values);
        }
      });
      return total;
    }
  },
  actions: {
    async fetchContext({ commit, dispatch }) {
      commit('setLoading', true);
      try {
        const response = await FetchService.getContext();
        commit('setContext', response.data);
      } catch (e) {
        console.log(e);
      }
      commit('setLoading', false);
    },
    async pushText({ commit, state, dispatch, getters }) {
      if (state.loading) {
        return;
      }
      try {
        const response = await FetchService.pushResult(getters.asText);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setContext(state, context) {
      if (context.items) {
        state.items = context.items;
      }
      if (context.steps) {
        state.steps = context.steps;
      }
    },
    setValue(state, {name, value}) {
      // console.log(name, value);
      Vue.set(state.values, name, value)
      localStorage.setItem('CALC_VALUES', JSON.stringify(state.values));
      // state.values[name] = value;
    },
    nextStep(state) {
      state.step++;
      localStorage.setItem('CALC_STEP', state.step);
    },
    prevStep(state) {
      state.step--;
      localStorage.setItem('CALC_STEP', state.step);
    },
    showPrice(state) {
      state.showPrices = true;
      localStorage.setItem('CALC_SHOW_PRICE', '1');
    },
    showCongratulation(state) {
      state.congratulation = true;
    },
    hideCongratulation(state) {
      state.congratulation = false;
    },
  },
};

export default calcModule;