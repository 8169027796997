import 'fotorama/fotorama.js';
import 'fotorama/fotorama.css';

$(function() {
    let opts = {
        thumbwidth: 220,
        thumbheight: 122,
        thumbmargin: 20
    };

    if (window.respond('medium')) {
        opts.thumbwidth = 110;
        opts.thumbheight = 122/2;
        opts.thumbmargin = 10;
    }

    if (window.respond('small')) {
        opts.thumbwidth = 110;
        opts.thumbheight = 122/2;
        opts.thumbmargin = 10;
    }

    $('.project__images-list').fotorama(opts);
});
