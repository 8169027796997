<template>
    <div class="step__input step-input">
        <div class="step-input__name">
            {{ title }}
        </div>
        <div class="step-input__values">
            <div class="step-input__value" v-for="radio in getRadios(code)">
                <input type="radio" :name="code" :id="code + radio.id" :value="radio.id" :checked="isCheckedRadio(code, radio.id)" @input="onInputRadio">
                <label :for="code + radio.id" class="step-input__label">
                    <span class="step-input__image">
                        <img :src="'/media/' + radio.image" alt="" v-if="radio.image">
                    </span>
                    <span class="step-input__value-name">
                        {{ radio.name }}
                    </span>
                </label>
            </div>
            <div class="step-input__value" v-for="check in getCheckboxes(code)">
                <input type="checkbox" :id="code + check.id" :name="check.group_code" :checked="isChecked(check.group_code)" @input="onInputCheckbox">
                <label :for="code + check.id" class="step-input__label">
                    <span class="step-input__image">
                        <img :src="'/media/' + check.image" alt="" v-if="check.image">
                    </span>
                    <span class="step-input__value-name">
                        {{ check.name }}
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

    export default {
        name: "StepInput",
        props: {
            title: String,
            code: String,
        },
        computed: {
            ...mapGetters('calc', [
                'getRadios',
                'getCheckboxes',
                'isChecked',
                'isCheckedRadio'
            ]),
        },
        methods: {
            ...mapMutations('calc',[
                'setValue'
            ]),
            onInputRadio(e) {
                if (e.target.checked) {
                    this.setValue({
                        name: e.target.name,
                        value: e.target.value
                    })
                }
            },
            onInputCheckbox(e) {
                this.setValue({
                    name: e.target.name,
                    value: e.target.checked
                });
            }
        }
    }
</script>

<style>

</style>