<template>
    <div class="step step_1">
        <div class="step__name">
            <div class="step__name-num">
                1
            </div>
            <div class="step__name-text">
                Архитектурные решения
            </div>
        </div>

        <div class="step__inputs">
            <div class="step__input step-input">
                <div class="step-input__name">
                    Задайте размеры дома
                </div>
                <div class="step-input__inputs">
                    <div class="step-input__input">
                        <label for="width" class="step-input__input-label">Ширина (м.)</label>
                        <input id="width" type="number" step="0.1" max="90" min="1" name="WIDTH" :value="getValue('WIDTH')" @input="onDimInput" class="step-input__input-value">
                    </div>
                    <div class="step-input__input">
                        <label for="length" class="step-input__input-label">Длина (м.)</label>
                        <input id="length" type="number" step="0.1" max="90" min="1" name="LENGTH" :value="getValue('LENGTH')" @input="onDimInput" class="step-input__input-value">
                    </div>
                </div>
            </div>

            <StepInput code="FLOORS" title="Выберите этажность дома" />

            <StepInput code="SATURATION" title="Выберите насыщенность планировки" />
        </div>


        <div class="step__buttons">
            <span></span>
            <a href="javascript:void(0);" class="step__next button" @click="nextStep" v-if="isNextAllowed">
                Далее &rarr;
            </a>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
    import StepInput from './StepInput.vue';
    import scroll from '../services/scroll.service.js';

    export default {
        name: "Step1",
        components: {
            StepInput
        },
        computed: {
            ...mapGetters('calc', [
                'isNextStepAllowed',
                'getValue',
            ]),
            isNextAllowed() {
                return this.isNextStepAllowed(['FLOORS', 'SATURATION']) &&
                    (this.getValue('WIDTH') > 0) &&
                    (this.getValue('LENGTH') > 0);
            }
        },
        methods: {
            ...mapMutations('calc', [
                'setValue',
                'nextStep'
            ]),
            onDimInput(e) {
                this.setValue({
                    name: e.target.name,
                    value: e.target.value
                });
            }
        },
        mounted() {
          scroll();
        }
    }
</script>

<style>

</style>