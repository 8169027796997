import axios from 'axios';

const FetchService = {
  async getContext() {
    return axios.get('/calc/api/context');
  },
  async pushResult(text) {
    return axios.post('/calc/api/result', {
      text,
    });
  },
};

export default FetchService;