<template>
  <div class="vue-app">
    <Calc></Calc>
  </div>
</template>

<script>
  import Calc from './Calc.vue';

  export default {
    name: "App",
    components: {Calc}
  }
</script>

<style lang="scss">
</style>