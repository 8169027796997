require('../fonts/Geometria/css/Geometria.css');
require('../scss/app.scss');


window.noZensmooth = true;

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');

require('./field/phone.js');
require('../components/smart-tabs/smart-tabs');

require('./project.js');
require('./menu.js');
require('./header.js');
require('./about.js');
require('./category.js');
require('./calc.js');
require('./vue/index.js');